export enum EDeviceTypes {
  ALL_DEVICES = "All Devices",
  TV = "TV",
  REFRIGERATOR = "Refrigerator",
  MICROWAVE_OVEN = "Microwave Oven",
  BULB = "Bulb",
  COMPUTER = "Computer",
  HOME_THEATER = "Home-theater",
  IRON = "Iron",
  WASHING_MACHINE = "Washing Machine",
  AIR_CONDITIONER = "Air Conditioner",
  FAN = "Fan",
  GENERAL = "General",
  WATER_DISPENSER = "Water Dispenser",
  RICE_COOKER = "Rice Cooker",
  INDUCTION_COOKER = "Induction Cooker",
  WATER_PUMP = "Water Pump",
  WATER_HEATER = "Water Heater"

}
