import { Col, Row } from "react-bootstrap";
import PuffLoader from "react-spinners/PuffLoader";
import styles from "./spinner.module.scss";

interface IProps {
  show: boolean;
  withOverlay?: boolean;
  size?: "sm" | "md" | "lg" | "xl";
  color?: string;
  speedMultiplier?: number;
  loadingPercentage?: string;
  loaderText?: string;
}

const SpinnerModal = (props: IProps) => {
  const {
    withOverlay = true,
    size = "lg",
    color = "#110740",
    loadingPercentage,
    loaderText,
  } = props;

  const getSize = () => {
    switch (size) {
      case "sm":
        return 20;
      case "md":
        return 30;
      case "lg":
        return 40;
      case "xl":
        return 60;
      default:
        return 60;
    }
  };

  if (!withOverlay) {
    return (
      <Row className="h-100 align-items-center justify-content-center">
        <Col className="col-auto">
          <div className="d-flex justify-content-center">
            <PuffLoader
              color={color}
              size={getSize()}
              speedMultiplier={props.speedMultiplier}
            />
          </div>
          {loaderText && (
            <div
              style={{ color }}
              className="d-flex justify-content-center mt-2 font-weight-500 font-size-14"
            >
              {loaderText}
            </div>
          )}
          {loadingPercentage !== undefined && (
            <div className="d-flex justify-content-center mt-2 font-weight-500 font-size-12 text-light">
              {loadingPercentage}%
            </div>
          )}
        </Col>
      </Row>
    );
  }

  return (
    <>
      {props.show && (
        <div
          className={[
            styles.spinnerContainer,
            "w-100 h-100 d-flex align-items-center justify-content-center",
          ].join(" ")}
        >
          <PuffLoader color="#110740" />
        </div>
      )}
    </>
  );
};
export default SpinnerModal;
