import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router";
import OtpInput from "react18-input-otp";
import {
  useLazyProfileQuery,
  useLazyValidateOTPQuery,
} from "../../../../../redux/api/user/userAPI";
import { OvstErrorCode } from "../../../enums/ovst-error-codes";
import { AppRoute } from "../../../interfaces/app-routes";
import MobileNumberOtpVerificationFooter from "../../../shared-components/mobile-number-otp-verification-footer/mobile-number-otp-verification-footer";
import AppButton from "../../../ui-elements/buttons/app-button/app-button";
import SpinnerModal from "../../../ui-elements/spinner/spinner";
import { showErrorMessage, showSuccessMessage } from "../../../utils/toast";
import "./mobile-number-otp-verification-form.scss";

interface IProps {
  number: string;
  whereTo: string;
}

const MobileNumberOtpVerificationForm = (props: IProps) => {
  const navigate = useNavigate();
  const { number, whereTo } = props;
  const [otp, setOtp] = useState("");
  const [isValid, setIsValid] = useState(true);
  const [isExpired, setIsExpired] = useState(false);
  const [enteredOtp, setEnteredOtp] = useState<boolean>(true);

  const handleChange = (value: string) => {
    setOtp(value);
    setEnteredOtp(value.length === 6 ? false : true);
  };

  const [triggerGetOTP, { isLoading: loadingGetOTP }] =
    useLazyValidateOTPQuery();
  const [getProfile, { isFetching: isGetProfileFetching }] =
    useLazyProfileQuery();

  const resetError = () => {
    setIsValid(true);
  };

  const onSubmit = () => {
    triggerGetOTP({ mobileNumber: number, otp: otp })
      .unwrap()
      .then(() => {
        if (whereTo === "reset") {
          navigate(AppRoute.RESET_PASSWORD);
        } else if (whereTo === "dashboard/user-profile") {
          getProfile()
            .unwrap()
            .then(() => {
              navigate(AppRoute.USER_PROFILE);
              showSuccessMessage("Mobile Number updated successfully");
            });
        } else if (whereTo === "dashboard") {
          getProfile()
            .unwrap()
            .then(() => {
              navigate(AppRoute.DASHBOARD);
            });
        }
      })
      .catch((error) => {
        if (error.status === 412) {
          if (error.ovstErrorCode === OvstErrorCode.OVST_SEC_0005 || error.ovstErrorCode === OvstErrorCode.OVST_CONS_0020) {
            setIsExpired(true);
            setIsValid(true);
          } else if (
            error.ovstErrorCode === OvstErrorCode.OVST_0001 ||
            error.ovstErrorCode === OvstErrorCode.OVST_APP_SEC_0004
          ) {
            setIsExpired(false);
            setIsValid(false);
          }
        } else {
          showErrorMessage(error.message || "Something went wrong");
        }
      });
  };

  useEffect(() => {
    if (otp.length < 1) {
      setIsValid(true);
    }
  }, [otp]);

  return (
    <>
      <Row className="justify-content-center">
        <Col className="mt-5">
          <OtpInput
            value={otp}
            onChange={handleChange}
            numInputs={6}
            isInputNum={true}
            placeholder={"------"}
            inputStyle={{
              width: "52px",
              height: "70px",
              margin: "0 10px",
              fontSize: "36px",
              borderWidth: "1px",
              borderStyle: "solid",
              borderColor: "#EAECF3",
              borderRadius: "8px",
              outline: "none",
              boxShadow: "none",
              color: "#69768B",
            }}
            className="input-field"
            focusStyle={{
              borderColor: "rgb(0, 123, 255)",
              outline: "none",
            }}
            errorStyle={{
              borderColor: "#D74242",
              outline: "none",
            }}
            hasErrored={!isValid}
            containerStyle={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          />
        </Col>
      </Row>
      <Row>
        {!isValid && (
          <Col className="error justify-content-center font-size-14 mt-2 text-center">
            The code you entered is incorrect. Please try again or request a new
            code.
          </Col>
        )}
        {isExpired && (
          <Col className="error justify-content-center font-size-14 mt-2 text-center">
            The code you entered is expired. Please request a new code.
          </Col>
        )}
      </Row>
      <Row className="justify-content-center">
        <Col md={10}>
          <AppButton
            text="Verify"
            className={`mt-5`}
            variant="blue"
            isLoading={loadingGetOTP}
            onClick={onSubmit}
            disabled={enteredOtp}
          />
        </Col>
      </Row>
      <Row className="justify-content-center mt-4">
        <MobileNumberOtpVerificationFooter
          whereTo={whereTo}
          number={number}
          handleOTP={handleChange}
          resetError={resetError}
          setIsExpired={() => setIsExpired(false)}
        />
      </Row>
      <SpinnerModal show={isGetProfileFetching} />
    </>
  );
};

export default MobileNumberOtpVerificationForm;
