import { Container } from "react-bootstrap";
import IPageInfo from "../../interfaces/page-info";
import AppMenuContent from "../../ui-elements/app-menu-content/app-menu-content";
import styles from "./side-bar.module.scss";

interface IProps {
  collapsed: boolean;
  routes: IPageInfo[];
  logos: string[];
}

const SideBar = (props: IProps) => {
  return (
    <Container className={`${styles["side-bar"]} h-100 w-100 m-0`}>
      <AppMenuContent {...props} />
    </Container>
  );
};

export default SideBar;
