import styles from "./active-inactive-indicator.module.scss";

interface IProps {
  isActive?: boolean;
  isLive?: boolean;
}

const ActiveInactiveIndicator = (props: IProps) => {
  const { isActive = true, isLive } = props;
  return (
    <>
      {isLive ? (
        <div className={`bg-red ${styles.circle}`}></div>
      ) : (
        <div
          className={`${styles.indicator} ${
            isActive ? styles.activeColor : styles.inactiveColor
          }`}
        ></div>
      )}
    </>
  );
};

export default ActiveInactiveIndicator;
