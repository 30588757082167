import { Col, Row } from "react-bootstrap";
import { EConsumptionValueComparison } from "../../../enums/consumption-comparison";
import { EUsageDataFrom } from "../../../enums/usage-data-from";
import { IEnergyView } from "../../../interfaces/entities/energy-view";
import UsageValue from "../../../ui-elements/usage-value/usage-value";
interface IProps {
  actualUsage: IEnergyView;
  scheduleUsage: IEnergyView;
  consumptionValueComparison?: EConsumptionValueComparison;
  title: string;
  isFetching: boolean;
  usageDateFrom: EUsageDataFrom;
  predictedUsageText: string;
}

const UsageCard = (props: IProps) => {
  const {
    actualUsage,
    scheduleUsage,
    consumptionValueComparison,
    title,
    usageDateFrom,
    isFetching,
  } = props;

  return (
    <div className="container-white">
      <Row>
        <Col className="text-light font-size-12 font-weight-500">{title}</Col>
      </Row>
      <Row className="mt-1">
        <Col>
          <UsageValue
            cost={actualUsage.energyBill}
            units={actualUsage.energyInUnits}
            subTitle="Actual Usage"
            isFetching={isFetching}
            comparisonWithLastMonth={consumptionValueComparison}
            usageDataFrom={usageDateFrom}
          />
        </Col>
        <Col xs={12} className="col-sm-6 col-xl-auto">
          <UsageValue
            cost={scheduleUsage.energyBill}
            units={scheduleUsage.energyInUnits}
            subTitle="Scheduled Usage"
            isFetching={isFetching}
          />
        </Col>
        {/* <Col>
          <UsageValue
            cost={0}
            units={0}
            subTitle="Predicted Usage"
            isFetching={false}
            isNoValues={true}
            predictedUsageText={predictedUsageText}
          />
        </Col> */}
      </Row>
    </div>
  );
};

export default UsageCard;
