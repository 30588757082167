export type MtIcon =
  | "home"
  | "schedule"
  | "electric_meter"
  | "devices_other"
  | "sunny"
  | "logout"
  | "menu"
  | "oven_gen"
  | "visibility"
  | "more_vert"
  | "more_horiz"
  | "notifications"
  | "expand_more"
  | "close"
  | "iron"
  | "bed"
  | "apartment"
  | "add"
  | "edit"
  | "arrow_drop_down"
  | "visibility_off"
  | "settings"
  | "emoji_objects"
  | "arrow_left"
  | "arrow_right"
  | "tv"
  | "kitchen"
  | "microwave"
  | "lightbulb"
  | "computer"
  | "speaker_group"
  | "local_laundry_service"
  | "ac_unit"
  | "mode_fan"
  | "location_on"
  | "chevron_left"
  | "chevron_right"
  | "calculate"
  | "trending_down"
  | "trending_up"
  | "running_with_errors"
  | "drive_file_rename_outline"
  | "star"
  | "arrow_downward"
  | "arrow_upward"
  | "search"
  | "circle"
  | "check"
  | "person_outline"
  | "border_color"
  | "delete"
  | "check_circle"
  | "check_indeterminate_small"
  | "info"
  | "filter_alt"
  | "discover_tune"
  | "calendar_month"
  | "apps"
  | "live_help"
  | "warning"
  | "smart_outlet"
  | "filter_none"
  | "sockets"
  | "keyboard_arrow_down"
  | "link"
  | "link_off"
  | "history_toggle_off"
  | "release_alert"
  | "error"
  | "all_inclusive"
  | "swap_horiz"
  | "autoplay"
  | "remove"
  | "device_thermostat"
  | "water_drop"
  | "stockpot"
  | "speed"
  | "energy"
  | "help"
  | "arrow_right_alt"
  | "playlist_add_check"
  | "missing_controller"
  | "drag_pan"
  | "chat"
  | "ad"
  | "smartphone"
  | "solar_power"
  | "energy"
  | "charger"
  | "battery_charging_80"
  | "electric_meter"
  | "keyboard_double_arrow_right"
  | "tune"
  | "touch_app"
  | "water_pump"
  | "water_heater";

/**
 * Reusable React component that displays Material icons.
 *
 * @interface IProps
 * @extends React.HTMLAttributes<HTMLSpanElement>
 */
interface IProps extends React.HTMLAttributes<HTMLSpanElement> {
  icon?: MtIcon;
  size?: number;
  color?: string;
  fill?: boolean;
  displayInline?: boolean;
}
/**
 * React functional component that renders an icon based on a given `MtIcon` string value.
 *
 * @param {IProps} props - Component props.
 * @returns {JSX.Element} - Rendered component.
 */
const MaterialIcon = (props: IProps) => {
  const { size, color, fill, displayInline, ...rest } = props;

  return (
    <span
      {...{
        ...rest,
        className: `${
          rest.className ? rest.className : ""
        } material-symbols-outlined`,
        style: {
          ...rest.style,
          fontSize: size ? size : 24,
          color: color ? color : rest.style?.color,
          display: displayInline ? "inline" : "flex",
          alignItems: "center",
          fontVariationSettings: `"FILL" ${
            fill ? 1 : 0
          }, "wght" 400, "GRAD" 0, "opsz" 48`,
        },
      }}
    >
      {props.icon}
    </span>
  );
};

export default MaterialIcon;
